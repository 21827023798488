<template>
  <c-flex
    as="nav"
    id="profileNavigation"
    :position="['fixed', 'static']"
    :bottom="[0]"
    :h="['60px', 'auto']"
    :w="['100vw', 'auto']"
    background="white"
    align-items="center"
    justify-content="space-around"
    border-right="1px solid #f2f2f2"
    pr="18px"
    pb="60px"
    :box-shadow="['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none']"
    :d="['flex', 'block']"
    :z-index="[999, 0]"
    :pt="[null, '33px']"
  >
    <c-link
      as="router-link"
      v-for="menu in menus"
      :key="menu.name"
      :to="menu.route"
      class="menu-item"
      exact-active-class="active"
    >
      <c-flex
        :flex-direction="['column', 'row']"
        :justify-content="['center', 'flex-start']"
        align-items="center"
        :h="[null, '66px']"
        :w="[null, '257px']"
        :border-radius="[null, '12px']"
        v-chakra="{
          '.active &': {
            bg: [null, '#f5f5f5'],
          },
        }"
      >
        <c-box
          w="7px"
          h="100%"
          border-top-left-radius="15px"
          border-bottom-left-radius="15px"
          v-chakra="{
            '.active &': {
              bg: [null, 'brand.900'],
            },
          }"
        />
        <c-image
          class="icon"
          :alt="menu.name"
          :src="menu.icon"
          w="24px"
          h="24px"
          :ml="[null, '16px']"
        />
        <c-text
          :class="{ active: menu.isActive }"
          :font-size="['10px', '14px']"
          :line-height="['21px']"
          :font-weight="['500']"
          :mx="[null, '20px']"
          color="#555555"
          v-chakra="{
            '&.active': {
              color: '#008C81',
            },
          }"
          v-html="menu.name"
        />
      </c-flex>
    </c-link>
  </c-flex>
</template>

<script>
export default {
  name: "SideMenu",
  computed: {
    menus() {
      const self = this;
      return [
        {
          name: "Dashboard",
          route: { name: "admin" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-home-active.svg");
            } else {
              return require("@/assets/icon-home.svg");
            }
          },
          get isActive() {
            return self.$route.name === "admin";
          },
        },
        {
          name: "Manajemen Diet Program",
          route: { name: "admin.diet-program" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-circles-active.svg");
            } else {
              return require("@/assets/icon-circles.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.diet-program");
          },
        },
        {
          name: "Manajemen Klien",
          route: { name: "admin.clients" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-user-active.svg");
            } else {
              return require("@/assets/icon-user.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.clients");
          },
        },
        {
          name: "Manajemen Diet <br> Coaching & Challenge",
          route: { name: "admin.coaching-challenge" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-run-active.svg");
            } else {
              return require("@/assets/icon-run.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes('admin.coaching-challenge');
          },
        },
        {
          name: "Manajemen Ahli Gizi",
          route: { name: "admin.nutritionists" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-users-active.svg");
            } else {
              return require("@/assets/icon-users.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.nutritionists");
          },
        },
        {
          name: "Manajemen Notifikasi",
          route: { name: "admin.notifications" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/ic-bell-active.svg");
            } else {
              return require("@/assets/ic-bell.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.notifications");
          },
        },
        {
          name: "Manajemen Diet Kuesioner",
          route: { name: "admin.diet-kuesioner" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-file-analytics-active.svg");
            } else {
              return require("@/assets/icon-file-analytics.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.diet-kuesioner");
          },
        },
        {
          name: "Manajemen Glossary",
          route: { name: "admin.glossary" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-box-active.svg");
            } else {
              return require("@/assets/icon-box.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.glossary");
          },
        },
        {
          name: "Manajemen Log Activity",
          route: { name: "admin.log-activity" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-activity-active.svg");
            } else {
              return require("@/assets/icon-activity.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.log-activity");
          },
        },
        {
          name: "Manajemen Kupon",
          route: { name: "admin.coupons" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-percentage-active.svg");
            } else {
              return require("@/assets/icon-percentage.svg");
            }
          },
          get isActive() {
            return self.$route.name.includes("admin.coupons");
          },
        },
      ];
    },
  },
};
</script>

<style>
body {
  padding-bottom: 75px;
}
</style>
